import React from "react"
import { Helmet } from "react-helmet"
import favicon from "../img/favicon.png"
const Header = () => {
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <meta charset="UTF-8" />
        <title>
          Tamil Code Camp - தமிழ் கோட் கேம்ப் - Free Tamil Programming Tutorials
        </title>
        <meta name="title" content="Tamil Code Camp - தமிழ் கோட் கேம்ப்" />
        <meta
          name="description"
          content="Tamil Code Camp - தமிழ் கோட் கேம்ப் Computer Programming இலவசமாக தமிழில் கற்றுக்கொள்ளுங்கள்"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://tamilcodecamp.org" />
        <meta
          property="og:title"
          content="Tamil Code Camp - தமிழ் கோட் கேம்ப்"
        />
        <meta
          property="og:description"
          content="Tamil Code Camp - தமிழ் கோட் கேம்ப் Computer Programming இலவசமாக தமிழில் கற்றுக்கொள்ளுங்கள்"
        />
        <meta property="og:locale" content="en_US" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <link rel="icon" href={favicon} type="image/png" />
        <link rel="icon" href={favicon} type="img/x-icon"></link>
        <meta name="title" content="Tamil Code Camp" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index,follow" />
        <meta name="robots" content="all" />
        <meta name="copyright" content="https://www.tamilcodecamp.org/" />
      </Helmet>
    </div>
  )
}

export default Header
