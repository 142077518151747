import React from "react"
import Layout from "../components/layout"
import logo from "../img/logo.svg"
import graphics from "../img/graphics.svg"
export default function Home() {
  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center paddingTopTen">
            <img src={logo} width="217.52" height="90"></img>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center paddingTopTen">
            <img src={graphics} width="333.15" height="342.95"></img>
          </div>
          <div className="col-md-12 text-center paddingTopTen">
            <h1>
              வணக்கம், <span>தமிழ் கோட் கேம்ப்</span>க்கு தங்களை அன்புடன்
              வரவேற்கிறோம்
            </h1>
          </div>
        </div>
      </div>
    </Layout>
  )
}
