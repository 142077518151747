import React from "react"
const Footer = props => {
  return (
    <footer>
      <div className="copyright text-center paddingTopTen">
        <p>&copy; 2024 Tamil Code Camp</p>
      </div>
    </footer>
  )
}

export default Footer
